'use client'

import {
  Box,
  Center,
  Heading,
  Text,
  Stack,
  Avatar,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Button,
  AvatarGroup,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  IconButton,
  HStack,
  Icon
} from '@chakra-ui/react'
import useSWR from 'swr'
import { getAll, save, deleteById } from '../../backend/api'
import AsyncData from '../AsyncData'
import useSWRMutation from 'swr/mutation'
import { useCallback } from 'react'
import React from 'react';
import { useSWRConfig } from 'swr'
import { CloseIcon, DeleteIcon } from '@chakra-ui/icons'
import { MdCloudOff, MdCloudQueue } from 'react-icons/md'
import Toast from '../toast'
import AdminFunction from '../AdminFunction'

const EventListItem = ({event_id, titel, desc, start, einde, authed_user, isPastEvent, public_agenda, public_event_id}) => {
  const {
    data: acc = [],
    isLoading: isLoadingEventUsers,
    error,
  } = useSWR(`eventusers/event/${event_id}`, getAll)

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit()
  }

  const {
    trigger: acceptEvent,
    error: acceptError,
    isLoading: isLoadingAccept,
  } = useSWRMutation(`eventusers/${event_id}`, save);

  const { addToast } = Toast();

  const { mutate } = useSWRConfig()
  const onSubmit = useCallback(async () => {
    await acceptEvent();
    if(acceptError) {
      addToast('Accepteren mislukt', 'Er is iets misgelopen bij het accepteren van het event', 'error');
    } else {
      addToast('Accepteren gelukt', 'U heeft het event succesvol geaccepteerd', 'success');
    }
    mutate(`eventusers/event/${event_id}`)
  }, [ acceptError, addToast, acceptEvent, mutate, event_id ])

  const { trigger: annulateEvent } = useSWRMutation(`eventusers/delete`, deleteById);

  const onAnnulate = useCallback(async (data) => {
    await annulateEvent(data);
    if(acceptError) {
      addToast('Annuleren mislukt', 'Er is iets misgelopen bij het annuleren van het event', 'error');
    } else {
      addToast('Annuleren gelukt', 'U heeft het event succesvol geannuleerd', 'success');
    }
    mutate(`eventusers/event/${event_id}`)
  }, [ acceptError, addToast, annulateEvent, mutate, event_id])

  function handleAnnulate(e) {
    e.preventDefault();
    onAnnulate(event_id)
  }
  const isAccepted = acc.some(acceptedEvent => acceptedEvent.user_id === authed_user.authed_user.id && acceptedEvent.event_id === event_id)
  const formattedStart = new Date(start).toLocaleString('nl-BE', { dateStyle: 'short', timeStyle: 'short' });
  const formattedEinde = new Date(einde).toLocaleString('nl-BE', { dateStyle: 'short', timeStyle: 'short' });

  const {
    trigger: deleteEvent,
    error: deleteError
  } = useSWRMutation(`events/delete`, deleteById);

  // const {
  //   trigger: deletePubEvent,
  //   error: deletePubError
  // } = useSWRMutation(`n8n/delete-public-event`, deletePublicEvent);

  const onDelete = useCallback(async () => {
    await deleteEvent(event_id);
    if(!deleteError)
      addToast('Event verwijderd', 'Het event is verwijderd', 'success');
    else
      addToast('Event verwijderen mislukt', 'Er is iets misgelopen bij het verwijderen van het event', 'error');
      mutate(`events`)
    }, [deleteEvent, deleteError, mutate, addToast]);

  // const onDeletePublic = useCallback(async () => {
  //   try {
  //     await deletePubEvent({public_event_id, public_agenda});
  //   }
  //   catch (error) {
  //     if(!deletePubError)
  //       addToast('Public Event verwijderd', 'Het public event is verwijderd', 'success');
  //     else
  //       addToast('Public Event verwijderen mislukt', 'Er is iets misgelopen bij het verwijderen van het public event', 'error');
  //   }
  // }, [deletePubEvent, deletePubError, addToast]);

  const { isOpen: isDeleteAlert, onOpen: onOpenDeleteAlert, onClose: onCloseDeleteAlert } = useDisclosure()
  const cancelRef = React.useRef()

  const handleDelete = useCallback(() => {
    // if(public_event_id && public_agenda) {
    //   onDeletePublic();
    // }
    onDelete();
  }, [onDelete])

  return (
    <Center py={6}>
      <Box
        minW={'240px'}
        minH={'320px'}
        maxW={'445px'}
        w={'full'}
        // eslint-disable-next-line react-hooks/rules-of-hooks
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        overflow={'hidden'}
        opacity={new Date(einde) > new Date() ? 1 : 0.25}
        >
        <Stack overflow={'hidden'}>
          <HStack justifyContent={'space-between'}>
          <Text
            color={public_agenda === "verhuur" ? 'green.500' : public_agenda === "werking" ? 'blue.500' : 'purple.500'}
            textTransform={'uppercase'}
            fontWeight={800}
            fontSize={'sm'}
            letterSpacing={1.1}>
            Event - {public_agenda ? public_agenda : "Privé"}
          </Text>
          <HStack>
            { public_event_id ?
              <Icon as={MdCloudQueue} boxSize={4} color='blue.500' />
              :
              <Icon as={MdCloudOff} boxSize={4} color='red.200' />
            }
            <AdminFunction>
              <IconButton aria-label={'Delete product'} icon={<DeleteIcon/>} colorScheme='transparent' size={'xs'} color={'red.200'} onClick={onOpenDeleteAlert}/>
            </AdminFunction>
          </HStack>
          <AlertDialog
            isOpen={isDeleteAlert}
            leastDestructiveRef={cancelRef}
            onClose={onCloseDeleteAlert}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Delete Event
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Are you sure? You can't undo this action afterwards.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onCloseDeleteAlert}>
                      Cancel
                    </Button>
                    <Button colorScheme='red' onClick={handleDelete} ml={3}>
                      Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
            </HStack>
          <Heading
            // eslint-disable-next-line react-hooks/rules-of-hooks
            color={useColorModeValue('gray.700', 'white')}
            fontSize={'2xl'}
            fontFamily={'body'}>
            {titel}
          </Heading>
          <Text color={'gray.500'} height={'40px'} maxW={'221px'} overflow={'hidden'}>
            {desc}
          </Text>
        </Stack>
        <Stack direction={'column'} spacing={0} fontSize={'sm'} paddingTop={"2"}>
          <Text fontWeight={600}>Uur</Text>
          <Text color={'gray.500'}>{formattedStart} · {formattedEinde}</Text>
        </Stack>
        <Stack mt={6} direction={'row'} spacing={4} align={'center'}>
        <Popover>
            <PopoverTrigger>
            <Box ml={'0'}>
              <AsyncData loading={isLoadingEventUsers} error={error}>
                <AvatarGroup size='md' max={4}>
                  {acc.length > 0 ? (
                    acc.filter((acceptedEvent) => acceptedEvent.event_id === event_id).map(acceptedEvent => (
                      <Avatar src={`data:image/png;base64,${acceptedEvent.profilepic}`}></Avatar>
                    ))
                  ) : (
                    <Avatar opacity={'0'} src={``}></Avatar>
                  )}
                </AvatarGroup>
              </AsyncData>
            </Box>
            </PopoverTrigger>
            <PopoverContent bg={useColorModeValue('white', 'gray.700')} color={useColorModeValue('gray.700', 'white')} zIndex={"9999"}>
            <PopoverHeader fontWeight='semibold'>Deze Mensen komen:</PopoverHeader>
            <PopoverArrow bg={'pink.500'} />
            <PopoverCloseButton bg='purple.500' />
            <PopoverBody>
              <AsyncData loading={isLoadingEventUsers} error={error}>
                  <Text>
                  {acc.filter((acceptedEvent) => acceptedEvent.event_id === event_id).map(acceptedEvent => (
                    <AsyncData loading={isLoadingEventUsers}>
                      {" · " + acceptedEvent.name}
                    </AsyncData>
                  ))}
                  </Text>
              </AsyncData>

            </PopoverBody>
            </PopoverContent>
        </Popover>
        {/* <form onSubmit={}></form> */}
        </Stack>
        <HStack mt={'2'}>
          <Button type='submit' colorScheme="green" w="fit-content" isLoading={isLoadingAccept} loadingText='Accepting' onClick={handleSubmit} width={'full'} isDisabled={isAccepted || isPastEvent}>
            Accept
          </Button>
          <Button colorScheme="red" isDisabled={!isAccepted || isPastEvent} onClick={handleAnnulate}>
            <CloseIcon/>
          </Button>
        </HStack>
      </Box>
    </Center>
  )
}
export default EventListItem
// isLoading={}