'use client'

import { 
  Avatar,
  Heading,
  Image,
  HStack,
  Tr,
  Td,
  IconButton,
} from '@chakra-ui/react'
import coingif from '../../assests/coin.gif';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { useSWRConfig } from 'swr';
import { updateCoin } from '../../backend/api';
import { getAll } from '../../backend/api/index.js';
import React from 'react';
import { useCallback } from 'react';
import AsyncData from '../AsyncData.jsx';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import AdminFunction from '../AdminFunction.jsx';

export default function Coin({authed_user, coin}) {
  const { mutate } = useSWRConfig()
  const {
    trigger: doCoin,
  } = useSWRMutation(`coins/update`, updateCoin);

  const onSubmit = useCallback(async (data) => {
    const { user_id, amount } = data;
    await doCoin({
      user_id: user_id,
      amount: amount
    });
    mutate(`coins`)
  }, doCoin);

  const {
    data: pictoire,
    isLoadingPictoire,
    errorPictoire,
  } = useSWR(`static/profilepics/${coin.user_id}`, getAll)
  return (
    <AsyncData loading={isLoadingPictoire}>
      <Tr>
        <Td paddingInline={{base:2, md:6}} overflow={'hidden'}>
          <HStack alignItems={'center'}>
              <Avatar size={'sm'} src={`data:image/png;base64,${pictoire}`}/>
              <Heading fontSize={'sm'} align={'center'}>{coin.user_name}</Heading>
          </HStack>
        </Td>
        <Td paddingInline={{base:2, md:6}}>
          <HStack>
            <Heading fontSize={'sm'} align={'center'}>{coin.amount}</Heading>
            <Image src={coingif} alt={'coin'} boxSize={'20px'} />
          </HStack>
        </Td>
        <AdminFunction>
          <Td paddingInline={{base:2, sm:6}}>
            <HStack justifyContent={'end'}>
              <IconButton aria-label={'Add coins'} icon={<AddIcon/>} colorScheme='green' onClick={() => onSubmit({user_id: coin.user_id, amount: coin.amount + 1})} />
              <IconButton aria-label={'Remove coins'} icon={<MinusIcon/>} colorScheme='red' onClick={() => onSubmit({user_id: coin.user_id, amount: coin.amount - 1})} isDisabled={coin.amount <= 0} />
            </HStack>
          </Td>
        </AdminFunction>
      </Tr>
    </AsyncData>
  )
};