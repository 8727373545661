'use client'

import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  Flex,
  Input,
  Stack,
  Radio,
  RadioGroup,
  HStack,
  FormControl,
  FormLabel,
  FocusLock,
  FormErrorMessage,
  ButtonGroup,
  Select,
  Fade,
} from '@chakra-ui/react'
import { PlusSquareIcon } from '@chakra-ui/icons'
import React, { useCallback, useState } from "react";
import { useForm } from 'react-hook-form'
import { save } from '../../backend/api/index.js';
import useSWRMutation from 'swr/mutation'
import { useDisclosure } from '@chakra-ui/react'
import Toast from '../toast'

const Form = ({ onCancel }) => {
  const [allowed, setAllowed] = useState(false);

  const { addToast } = Toast();

  const {
    trigger: saveEvent,
    error: saveError
  } = useSWRMutation('events', save);

  const {
    handleSubmit,
    register,
    formState: {errors, isSubmitting},
    setValue,
    onchange
  } = useForm({
    defaultValues: {
      isPublic: '0'
    }
  })

  const onSubmit = useCallback(async (data) => {
    const { title, desc, start, end, isPublic, public_agenda } = data;
    await saveEvent({
      title: title,
      desc: desc,
      start: start,
      end: end,
      public: parseInt(isPublic),
      public_agenda: public_agenda ? public_agenda : "",
      public_event_id: "",
    });
    if(saveError) {
      addToast('Oh no!', 'Failed to save event', 'error');
    } else {
      addToast('Success', 'Event saved', 'success');
    }
}, [ saveEvent ])

  function handleChange(value) {
    setAllowed(value === '1');
    setValue('isPublic', value);
  }

  return (
    <Stack spacing={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <FormLabel>Event title</FormLabel>
          <Input
            placeholder="Event title"
            bg={'gray.100'}
            border={0}
            color={'gray.500'}
            _placeholder={{
            color: 'gray.500',
            }}
            {...register('title', {
              required: 'This is required',
              minLength: { value: 4, message: 'Minimum length should be 4' },
            })}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Event description</FormLabel>
          <Input
            placeholder="Beschrijving"
            bg={'gray.100'}
            border={0}
            color={'gray.500'}
            _placeholder={{
              color: 'gray.500',
            }}
            {...register('desc')}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Start</FormLabel>
          <Input
            placeholder="Selecteer start"
            size="md"
            type="datetime-local"
            {...register('start', {
              required: 'This is required'
            })}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Einde</FormLabel>
          <Input
            placeholder="Selecteer einde"
            size="md"
            type="datetime-local"
            {...register('end', {
              required: 'This is required'
            })}
          />
        </FormControl>
        <FormControl>
          <HStack justifyContent={'space-between'} mt={'1'}>
            <FormLabel>Publiek</FormLabel>
            <RadioGroup defaultValue='0' onChange={handleChange}>
              <HStack>
                <Radio value='1' onChange={handleChange} 
                {
                  ...register('isPublic')
                }>Ja</Radio>
                <Radio value='0' onChange={handleChange}
                {
                  ...register('isPublic')
                }>Nee</Radio>
              </HStack>
            </RadioGroup>
          </HStack>
        </FormControl>
        <Fade in={allowed} >
          {!allowed ? null : (
            <FormControl>
              <FormLabel>Publiek agenda</FormLabel>
              <Select placeholder='Select option' padding={'0'} mb={'2'} background={"gray.700"} disabled={!allowed} {
                ...register('public_agenda', {
                  required: 'This is required'
                })
              } onChange={onchange}
              >
                <option value="verhuur">Verhuur</option>
                <option value="werking">Werking</option>
              </Select>
            </FormControl>
          )}
        </Fade>
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
        <ButtonGroup display='flex' justifyContent='flex-end'>
          <Button variant='outline' onClick={onCancel}>
            Cancel
          </Button>
          <Button colorScheme='teal' type="submit" isLoading={isSubmitting} onClick={onCancel}>
            Save
          </Button>
        </ButtonGroup>
      </form>
    </Stack>
  )
}
const CreateEventButton = () => {

  const { onOpen, onClose, isOpen } = useDisclosure()

  return (
    <Flex justifyContent="center" mt={4}>
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur={false} placement="bottom" isLazy>
        <PopoverTrigger>
          <Button rightIcon={<PlusSquareIcon />} colorScheme="green" w="fit-content">
            Create
          </Button>
        </PopoverTrigger>
        <PopoverContent p={5}>
          <FocusLock returnFocus persistentFocus={false}>
            <PopoverArrow />
            <PopoverCloseButton />
            <Form onCancel={onClose} />
          </FocusLock>
        </PopoverContent>
       </Popover>
    </Flex>
  )
}

export default CreateEventButton