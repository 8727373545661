'use client'

import {
  Button,
  Flex,
  Text,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Image,
} from '@chakra-ui/react'

import bg from '../assests/bg.jpg'
import logo_wit from '../assests/logo_wit.png'
import { useForm, FormProvider } from 'react-hook-form'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/Auth.context';
import React from 'react';
import Toast from '../components/toast'

export default function SplitScreen() {
  const { loading, login } = useAuth();
  const navigate = useNavigate();

  const { addToast } = Toast();

  const methods = useForm();

  const { handleSubmit, register } = methods;

  const handleLogin = useCallback(
    async ({ username, password}) => {
      const loggedIn = await login(username, password);
      if(loggedIn) {
        addToast('Login succesvol', 'U bent succesvol ingelogd', 'success');
        navigate({
          pathname: '/',
          replace: true,
        });
      }
      else {
        addToast('Login mislukt', 'Uw gebruikersnaam of wachtwoord is fout', 'error');
      }
    }, [login, navigate, addToast]
  );

  return (
    <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }} backgroundColor={"gray.900"} color={'white'}>
      <Flex p={8} flex={1} align={'center'} justify={'center'}>
        <Stack spacing={4} w={'full'} maxW={'md'}>
          <Image
          src={
            logo_wit
          }></Image>
          <Heading fontSize={'2xl'}>Sign in to your account</Heading>
          <FormProvider {...methods}>
            <form onSubmit={ handleSubmit(handleLogin)}>
            <FormControl id="username">
              <FormLabel>Email</FormLabel>
              <Input type="text" 
              {...register('username', {
                required: 'This is required',
                minLength: { value: 4, message: 'Minimum length should be 4' },
              })}/>
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" 
              {...register('password', {
                required: 'This is required',
                minLength: { value: 4, message: 'Minimum length should be 4' },
              })}/>
            </FormControl>
            <Stack spacing={6}>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'end'}>
                {/* <Checkbox>Remember me</Checkbox> */}
                <Text color={'blue.500'}>Forgot password?</Text>
              </Stack>
              <Button colorScheme={'blue'} variant={'solid'} isLoading={loading} disabled={loading} type='submit'>
                Sign in
              </Button>
            </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Flex>
      <Flex flex={1}>
        <Image
          alt={'Login Image'}
          objectFit={'cover'}
          src={
            bg
          }
        />
      </Flex>
    </Stack>
  )
}
