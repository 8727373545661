import {
    createContext,
    useState,
    useCallback,
    useEffect,
    useMemo,
    useContext,
  } from 'react';
import useSWRMutation from 'swr/mutation';
import * as api from '../backend/api/index.js'
import Cookies from 'js-cookie';

const JWT_TOKEN_KEY = 'jwtToken';
const USER_ID_KEY = 'userId';
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [ready, setReady] = useState(false);
    const [isAuthed, setIsAuthed] = useState(false);
    const [token, setToken] = useState(Cookies.get(JWT_TOKEN_KEY));
    const [user, setUser] = useState(false);
    const { isMutating: loading, error, trigger: doLogin } = useSWRMutation('/token', api.post);
    const { trigger: doCheckAuthed } = useSWRMutation('users/me', api.getAll);

    const checkAuthed = useCallback(async (token) => {
        try {
            setUser(await doCheckAuthed(token));
            setIsAuthed(true);
            return true;
        } catch (error) {
            setIsAuthed(false);
            return false;
        }
    }, [doCheckAuthed]);

    const checkToken = useCallback(async (token) => {
        try {
            await doCheckAuthed(token);
            setIsAuthed(true);
            return true;
        } catch (error) {
            setIsAuthed(false);
            return false;
        }
    }, [doCheckAuthed]);

    useEffect(() => {
        api.setAuthToken(token);
        checkAuthed(token); // No need to await, just trigger the check
        setReady(true);
    }, [token, checkAuthed]);

    const login = useCallback(
        async (username, password) => {
            try {
                const token = await doLogin({ username, password });
                setToken(token);
                Cookies.set(JWT_TOKEN_KEY, token, { expires: 121, sameSite: 'strict', secure: true});
                api.setAuthToken(token);
                await checkAuthed(token);
                setReady(true);
                return true;
            } catch (error) {
                return false;
            }
        },
        [doLogin, checkAuthed]
    );

    const logout = useCallback(() => {
        setToken(null);
        setUser(null);
        Cookies.remove(JWT_TOKEN_KEY);
        localStorage.removeItem(USER_ID_KEY);
    }, []);

    const value = useMemo(
        () => ({
            token,
            user,
            error,
            ready,
            loading,
            isAuthed,
            login,
            logout,
            setIsAuthed,
            checkAuthed,
            checkToken,
        }),
        [token, user, error, ready, loading, isAuthed, login, logout, setIsAuthed, checkAuthed, checkToken]
    );

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
