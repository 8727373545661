'use client'

import {
  Box,
  Center,
  Text,
  Stack,
  useColorModeValue,
  HStack,
  IconButton,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import useSWRMutation from 'swr/mutation'
import { deleteById } from '../../backend/api'
import { useCallback } from 'react'
import { useSWRConfig } from 'swr'
import React from 'react'
import Toast from '../toast'
import AdminFunction from '../AdminFunction'

const Product = ({naam, prijs, id}) => {
  const aantal = 0;
  function updateBar(id){
    var totaalSelected = parseFloat(document.getElementById(id).innerHTML)
    var totaal = parseFloat(document.getElementById('totaal').innerHTML)
    totaal += prijs;
    totaalSelected += 1;
    document.getElementById('totaal').innerHTML = totaal.toFixed(2);
    document.getElementById(id).innerHTML = totaalSelected.toFixed(0);
  }

  const { addToast } = Toast();

  const { mutate } = useSWRConfig()
  const {
    trigger: deleteProduct,
    error: deleteError
  } = useSWRMutation(`articles/delete`, deleteById);

  const onDelete = useCallback(async (data) => {
    await deleteProduct(data);
    if(!deleteError)
    addToast('Product verwijderd', 'Het product is verwijderd', 'success');
  else
    addToast('Product verwijderd mislukt', 'Er is iets misgelopen bij het verwijderen van het product', 'error');
    mutate(`articles`)
  }, [deleteProduct, deleteError, mutate, addToast]);

  const { isOpen: isDeleteAlert, onOpen: onOpenDeleteAlert, onClose: onCloseDeleteAlert } = useDisclosure()
  const cancelRef = React.useRef()

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete])

  return (
    <Center>
      <Box
        minW={'110'}
        minH={'110'}
        maxW={'110'}
        maxH={'110'}
        w={'full'}
        // eslint-disable-next-line react-hooks/rules-of-hooks
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={2}
        mr={1}
        mb={1}
        >
        <Stack>
          <HStack spacing={1.5}>
            <Text
              color={'green.500'}
              textTransform={'uppercase'}
              fontWeight={100}
              fontSize={'sm'}
              letterSpacing={1.1}>
              Aantal:
            </Text>
            <Text
              className='product'
              color={'green.500'}
              textTransform={'uppercase'}
              fontWeight={100}
              fontSize={'sm'}
              letterSpacing={1.1}
              id={id}>
            {aantal}
            </Text>
            <AdminFunction>
              <IconButton aria-label={'Delete product'} icon={<DeleteIcon/>} colorScheme='transparent' size={'xs'} color={'red.200'} onClick={onOpenDeleteAlert}/>
            </AdminFunction>
            <AlertDialog
            isOpen={isDeleteAlert}
            leastDestructiveRef={cancelRef}
            onClose={onCloseDeleteAlert}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Delete Product
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Are you sure? You can't undo this action afterwards.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onCloseDeleteAlert}>
                      Cancel
                    </Button>
                    <Button colorScheme='red' onClick={handleDelete} ml={3}>
                      Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </HStack>
          <HStack justifyContent={'space-between'} onClick={() => updateBar(id)}>
            <Text
                // eslint-disable-next-line react-hooks/rules-of-hooks
                color={useColorModeValue('gray.700', 'white')}
                fontSize={'sm'}
                fontFamily={'body'}
                maxW={'80'}
                overflow={'auto'}>
                {naam}
            </Text>
            <Text
                // eslint-disable-next-line react-hooks/rules-of-hooks
                color={useColorModeValue('gray.700', 'white')}
                fontSize={'sm'}
                fontFamily={'body'}>
                {prijs}
            </Text>
          </HStack>
        </Stack>
      </Box>
    </Center>
  )
}
export default Product
