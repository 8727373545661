/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useState } from "react"
import ReactMarkdown from 'react-markdown'
import { 
  Box,
  Heading,
  Card,
  CardHeader,
  CardBody,
  Stack,
} from '@chakra-ui/react'

function Changelog() {
  const [posts, setPosts] = useState([])

  // For post content
  const importAllPosts = (r) => r.keys().map(r)
  const markdownFiles = importAllPosts(
  require.context('./ChangeLogArchives', false, /\.md$/)
  )
    .sort()
    . reverse()

  useEffect(() => {
    const getPosts = async () => {
      await Promise.all(
        markdownFiles.map((file) => {
        return fetch(file).then((res) => res.text())
        })
      )
      .then((res) => setPosts(res))
      .catch((err) => console.error(err))
    }
    getPosts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Card>
        <CardHeader>
          <Heading size='md'>CHANGELOG</Heading>
        </CardHeader>
        <CardBody>
          <Stack spacing='4'>
            {posts.map((post, index) => (
              <Box key={index}>
                <ReactMarkdown
                  components={{
                    li: ({...props}) => (
                      <li style={{ marginLeft: '1rem' }} {...props}></li>
                    ),
                  }}
                >{post}</ReactMarkdown>
              </Box>
            ))}
          </Stack>
        </CardBody>
      </Card>
    </>
  )
}
export default Changelog