'use client'

import {
  Box,
  Center,
  Text,
  Stack,
  useColorModeValue,
  HStack,
  IconButton,
  VStack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react'

import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { useCallback } from 'react'
import useSWRMutation from 'swr/mutation'
import { updatePayment, deleteById } from '../../backend/api'
import { useSWRConfig } from 'swr'
import React from 'react';
import Toast from '../toast'
import { DeleteIcon } from '@chakra-ui/icons'
import AdminFunction from '../AdminFunction'

export default function Payment({payments = [], user_id, user_name}) {

  const { mutate } = useSWRConfig()
  const {
    trigger: doPayment,
  } = useSWRMutation(`payments/update`, updatePayment);
  const { addToast } = Toast();
  const onSubmit = useCallback(async (data) => {
    const { id, name, amount, user_id, is_paid } = data;
    await doPayment({
      id: id,
      name: name,
      amount: amount,
      user_id: user_id,
      is_paid: is_paid
    });
    mutate(`payments`)
    addToast("Payment updated", "Payment has been updated", "success")
  }, [doPayment, mutate, addToast]);

  const {
    trigger: deletePayment,
    error: deleteError
  } = useSWRMutation(`payments/delete`, deleteById);

  const onDelete = useCallback(async (data) => {
    await deletePayment(data);
    if(!deleteError)
      addToast('Payment verwijderd', 'Payment is verwijderd', 'success');
    else
      addToast('Payment verwijderen mislukt', 'Er is iets misgelopen bij het verwijderen van payment', 'error');
      mutate(`payments`)
  }, [deletePayment, deleteError, mutate, addToast]);

  const { isOpen: isDeleteAlert, onOpen: onOpenDeleteAlert, onClose: onCloseDeleteAlert } = useDisclosure()
  const cancelRef = React.useRef()

  const handleDelete = useCallback((p_id) => {
    onDelete(p_id);
  }, [onDelete])

  return (
    <Center mt={'3'} minW={'100%'}>
      <Box
        // eslint-disable-next-line react-hooks/rules-of-hooks
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'md'}
        p={6}
        overflow={'hidden'}
        minW={'100%'}>
        <Stack>
          <Text
            color={'purple.500'}
            textTransform={'uppercase'}
            fontWeight={800}
            fontSize={'sm'}
            letterSpacing={1.1}>
            Payments van {user_name}
          </Text>
          <VStack alignItems={''}>
            <Text
              // eslint-disable-next-line react-hooks/rules-of-hooks
              color="red.200"
              fontSize={'md'}
              fontFamily={'body'}>
              Nog te betalen:
            </Text>
            {/* payments.filter((payment) => payment.user_id === user_id) */}
            {payments.filter((payment) => payment.user_id === user_id).filter((payment) => payment.is_paid === false).map(p => (
              <HStack key={p.id} justifyContent={'space-between'}>
                <HStack>
                  <Text
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    color={useColorModeValue('gray.700', 'white')}
                    fontSize={'md'}
                    fontFamily={'body'}>
                    {p.name}</Text>
                  <Text
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    color={useColorModeValue('gray.700', 'white')}
                    fontSize={'md'}
                    fontFamily={'body'}
                    p={'0'}>
                    {p.amount} EUR
                  </Text>
                </HStack>
                <HStack>
                  <IconButton
                    background={''}
                    aria-label="Add payment"
                    color={'green.500'}
                    icon={<CheckIcon />}
                    onClick={() => onSubmit({id: p.id, name: p.name, amount: p.amount, user_id: p.user_id, is_paid: true})}
                  />
                </HStack>
              </HStack>
            ))}
          </VStack>
          <VStack alignItems={''}>
            <Text
              // eslint-disable-next-line react-hooks/rules-of-hooks
              color="green.200"
              fontSize={'md'}
              fontFamily={'body'}>
              Al Betaald:
            </Text>
            {payments.filter((payment) => payment.user_id === user_id).filter((payment) => payment.is_paid === true).map(p => (
              <HStack key={p.id} justifyContent={'space-between'}>
                <HStack
                textDecoration={"line-through"}
                opacity={'40%'}>
                  <Text
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    color={useColorModeValue('gray.700', 'white')}
                    fontSize={'md'}
                    fontFamily={'body'}>
                    {p.name}</Text>
                  <Text
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    color={useColorModeValue('gray.700', 'white')}
                    fontSize={'md'}
                    fontFamily={'body'}
                    p={'0'}
                    >
                    {p.amount} EUR
                  </Text>
                </HStack>
                <HStack>
                  <IconButton
                    background={''}
                    color={'orange'}
                    aria-label="Remove payment"
                    icon={<CloseIcon />}
                    onClick={() => onSubmit({id: p.id, name: p.name, amount: p.amount, user_id: p.user_id, is_paid: false})}
                  />
                  <AdminFunction>
                    <IconButton 
                      background={''}
                      color={'red.500'}
                      aria-label="Delete payment"
                      icon={<DeleteIcon />} 
                      onClick={onOpenDeleteAlert}/>
                  </AdminFunction>
                  <AlertDialog
                    isOpen={isDeleteAlert}
                    leastDestructiveRef={cancelRef}
                    onClose={onCloseDeleteAlert}
                    >
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                          Delete Rament
                        </AlertDialogHeader>

                        <AlertDialogBody>
                          Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                          <Button ref={cancelRef} onClick={onCloseDeleteAlert}>
                            Cancel
                          </Button>
                          <Button colorScheme='red' onClick={() => handleDelete(p.id)} ml={3}>
                            Delete
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </HStack>
              </HStack>
            ))}
          </VStack>
        </Stack>
      </Box>
    </Center>
  )
}
