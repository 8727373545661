import { Box, Text, VStack, HStack, useColorModeValue, useSteps, Stepper, Step, StepIndicator, StepStatus, StepIcon, StepTitle, StepDescription, StepSeparator, Heading, IconButton, Hide } from '@chakra-ui/react'
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton } from '@chakra-ui/react'
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react'
import { SmallCloseIcon, TimeIcon, DeleteIcon } from '@chakra-ui/icons';
import { useState, useEffect } from 'react';
import useSWR from 'swr';
import { getAll, deleteById } from '../../backend/api/index.js';
import AsyncData from '../../components/AsyncData.jsx';
import { EditIcon } from '@chakra-ui/icons';
import { MdOutlinePictureAsPdf } from "react-icons/md";
import React from 'react';
import { useDisclosure, Container } from '@chakra-ui/react';
import { useCallback } from 'react';
import useSWRMutation from 'swr/mutation';
import AdminFunction from '../../components/AdminFunction.jsx';

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Toast from '../../components/toast.jsx';


export default function Contract({submission_id}) {
    const { addToast } = Toast();

    const {
        data: submissionDetails = [],
        isLoading: isLoadingSubmissionDetails,
    } = useSWR(`docu/submissions/${submission_id}`, getAll);

    const steps = [
        { title: 'Document' },
        { title: 'Verzender' },
        { title: 'Ontvanger' },
        { title: 'Afgerond'}
    ]
    const { activeStep, setActiveStep } = useSteps({
        index: 0,
        count: steps.length,
    })
    const stepperColor = (activeStep >= steps.length - 1) ? 'green' : 'orange';

    const [ontvanger, setOntvanger] = useState('');
    const [verzender, setVerzender] = useState('');

    const processSubmissionDetails = useCallback((submission) => {
        if (submission && submission.submitters) {
            let newOntvanger = null;
            let newVerzender = null;

            for (let i = 0; i < submission.submitters.length; i++) {
                if (submission.submitters[i].role === 'De Galjaar') {
                    newVerzender = submission.submitters[i];
                } else if (submission.submitters[i].role === 'Huurder') {
                    newOntvanger = submission.submitters[i];
                }
            }

            setOntvanger({ ...newOntvanger });
            setVerzender({ ...newVerzender });

            if (newVerzender && newVerzender.status === 'sent') {
                setActiveStep(1);
            }
            if (newVerzender && newVerzender.status === 'completed') {
                setActiveStep(2);
            }
            if (newOntvanger && newOntvanger.status === 'sent') {
                setActiveStep(2);
            }
            if (newOntvanger && newOntvanger.status === 'completed') {
                setActiveStep(4);
            }
        }
    }, [setOntvanger, setVerzender, setActiveStep])

    useEffect(() => {
        if (submissionDetails) {
            processSubmissionDetails(submissionDetails);
        }
    }, [submissionDetails, processSubmissionDetails]);

    const { isOpen, onOpen, onClose } = useDisclosure()

    // PDF
    const [activeSubmitter, setActiveSubmitter] = useState('');
    const [activePdf, setActivePdf] = useState('');
    function openPDF(submitter) {
        setActiveSubmitter(submitter);
        setActivePdf(submitter.documents[0].url);
        onOpen();
    }
    // PDF

    const {
        trigger: deleteEvent,
        error: deleteError
    } = useSWRMutation(`docu/submissions`, deleteById);

    const { isOpen: isDeleteAlert, onOpen: onOpenDeleteAlert, onClose: onCloseDeleteAlert } = useDisclosure()
    const cancelRef = React.useRef()

    const onDelete = useCallback(async (data) =>{
        await deleteEvent(data);
        if(!deleteError)
            addToast('Contract verwijderd', 'Het contract is verwijderd', 'success');
        else
            addToast('Contract verwijderen mislukt', 'Er is iets misgelopen bij het verwijderen van het contract', 'error');
    }, [deleteEvent, deleteError, addToast])

    const handleDelete = useCallback(() => {
        onDelete(submission_id);
        onCloseDeleteAlert();
      }, [submission_id, onDelete, onCloseDeleteAlert])

    return (
        <Container mt={'3'} maxWidth={'560px'} m={0}>
            <Box bg={useColorModeValue('white', 'gray.900')} boxShadow={'2xl'} rounded={'md'} p={6} overflow={'hidden'}>
                <VStack alignItems={'start'}>
                    <AsyncData loading={isLoadingSubmissionDetails}>
                            <HStack justifyContent={'space-between'} w={'100%'}>
                                <Heading color={'purple.500'} textTransform={'uppercase'} fontWeight={800} fontSize={'m'} letterSpacing={1.1} mb='2'>{submissionDetails.template_name}</Heading>
                                <AdminFunction>
                                    <IconButton aria-label='delete' icon={<DeleteIcon/>} size={'sm'} onClick={onOpenDeleteAlert} colorScheme='transparent' color={'red.200'} />
                                </AdminFunction>
                            </HStack>

                            <HStack justifyContent={'space-between'} w={'100%'}>
                                <Text overflow={'hidden'} whiteSpace={'nowrap'}>Van: {verzender.email}</Text>

                                {verzender.status === "sent" && <IconButton aria-label='verzender-sign-link' icon={<EditIcon/>} size={'sm'} onClick={() => window.open(verzender.url, "_blank")} colorScheme='transparent' color={'blue.500'} />}

                                {verzender.status === "completed" && <IconButton aria-label='download-pdf' icon={<MdOutlinePictureAsPdf/>} size={'sm'} onClick={() => openPDF(verzender)} colorScheme='transparent' color={'blue.500'}/>}
                            </HStack>

                            <HStack justifyContent={'space-between'} w={'100%'}>
                                <Text overflow={'hidden'} whiteSpace={'nowrap'}>Naar: {ontvanger.email}</Text>

                                {ontvanger.status === "sent" && <IconButton aria-label='ontvanger-sign-link' icon={<EditIcon/>} size={'sm'} onClick={() => window.open(ontvanger.url, "_blank")} colorScheme='transparent' color={'blue.500'}/>}

                                {ontvanger.status === "completed" && <IconButton aria-label='download-pdf' icon={<MdOutlinePictureAsPdf/>} size={'sm'} onClick={() => openPDF(ontvanger)} colorScheme='transparent' color={'blue.500'}/>}
                            </HStack>

                            <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={'lg'}>
                                <DrawerOverlay />
                                <DrawerContent>
                                    <DrawerCloseButton />
                                    <DrawerHeader>Verhuur Contract bekijken</DrawerHeader>
                                    <DrawerBody>
                                        <Text mb={3}>Afgerond op: {new Date(activeSubmitter.completed_at).toLocaleString('nl-BE', { dateStyle: 'short', timeStyle: 'short' })}</Text>
                                        <DocViewer documents={[{ uri: activePdf, name: 'VerhuurContract.pdf'}]} renderers={[DocViewerRenderers.PDF]}
                                        config={{pdfVerticalScrollByDefault: true, header: { disableHeader: true, disableFileName: true, retainURLParams: false }}}/>
                                    </DrawerBody>
                                    <DrawerFooter></DrawerFooter>
                                </DrawerContent>
                            </Drawer>

                            <AlertDialog isOpen={isDeleteAlert} leastDestructiveRef={cancelRef} onClose={onCloseDeleteAlert}>
                                <AlertDialogOverlay>
                                    <AlertDialogContent>
                                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                        Delete Contract
                                    </AlertDialogHeader>

                                    <AlertDialogBody>
                                        Are you sure? You can't undo this action afterwards.
                                    </AlertDialogBody>

                                    <AlertDialogFooter>
                                        <Button ref={cancelRef} onClick={onCloseDeleteAlert}>
                                        Cancel
                                        </Button>
                                        <Button colorScheme='red' onClick={handleDelete} ml={3}>
                                        Delete
                                        </Button>
                                    </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialogOverlay>
                            </AlertDialog>
                    </AsyncData>
                    <Stepper size='sm' index={activeStep} mt="5" colorScheme={stepperColor} mb="5">
                        {steps.map((step, index) => (
                        <Step key={index} >
                            <StepIndicator>
                                    <StepStatus
                                    complete={<StepIcon />}
                                    incomplete={<SmallCloseIcon />}
                                    active={<TimeIcon />}
                                    />
                            </StepIndicator>

                            <Hide below='md'>
                            <Box flexShrink='0'>

                                    <StepTitle>{step.title}</StepTitle>
                                    <StepDescription></StepDescription>
                            </Box>
                            </Hide>

                            <StepSeparator />
                        </Step>
                        ))}
                    </Stepper>
                    {activeStep === 4 && <Text>Contract is afgerond</Text>}
                    {activeStep === 2 && <Text>Contract is verzonden: Huurder</Text>}
                    {activeStep === 1 && <Text>Contract is verzonden: De Galjaar</Text>}
                </VStack>
            </Box>
      </Container>
    )
}
