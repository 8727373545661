'use client'

import { Flex, Container, Heading, SimpleGrid, HStack, Button, Stack, FormControl, FormLabel, Input, FormErrorMessage, ButtonGroup } from "@chakra-ui/react";
import useSWR from 'swr';
import { getAll } from '../backend/api/index.js';
import AsyncData from "../components/AsyncData.jsx";
import Contract from "../components/contracts/contract.js";
import AdminFunction from '../components/AdminFunction.jsx';
import { useDisclosure } from '@chakra-ui/react';
import { Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, FocusLock } from '@chakra-ui/react';
import { PlusSquareIcon } from "@chakra-ui/icons"
import { useForm } from 'react-hook-form'
import { useCallback } from 'react'
import useSWRMutation from 'swr/mutation'
import { save } from '../backend/api/index.js'
import Toast from '../components/toast.jsx';

const Form = ({ onCancel }) => {
    const { addToast } = Toast();

    const {
      trigger: saveSubmission,
      error: saveError
    } = useSWRMutation('docu/submissions', save);

    const onSubmit = useCallback(async (data) => {
        const { name, email } = data;
        await saveSubmission({
            template_id: 1,
            submitters: [
                {"name": "JC De Galjaar", "email": "verhuur@degaljaar.be", "role": "De Galjaar"},
                {"name": name, "email": email, "role": "Huurder"}
            ],
            send_email: true
        });
        if (saveError) {
          addToast('Contract niet aangemaakt!', 'Er is iets fout gelopen', 'error');
        } else {
          addToast('Contract aangemaakt!', 'Contract succesvol aangemaakt', 'success');
        }
    }, [ saveSubmission, addToast, saveError])

    const {
      handleSubmit,
      register,
      formState: {errors, isSubmitting},
      onchange,
    } = useForm()

    return (
      <Stack spacing={4}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <FormLabel htmlFor="name">Naam</FormLabel>
            <Input
                id="name"
                type="text"
                {...register('name', {required: true})}
                onChange={onchange}/>
            </FormControl>
          <FormControl>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              type="email"
              {...register('email', {required: true})}
              onChange={onchange}/>
          </FormControl>
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
          <ButtonGroup display='flex' justifyContent='flex-end' mt={'2'}>
            <Button variant='outline' onClick={onCancel}>
              Cancel
            </Button>
            <Button colorScheme='teal' type="submit" isLoading={isSubmitting} onClick={onCancel}>
              Save
            </Button>
          </ButtonGroup>
        </form>
      </Stack>
    )
  }


const ContractList = () => {

    const {
        data: submissions = [],
        isLoading: isLoadingSubmissions,
    } = useSWR(`docu/submissions`, getAll);

    const { onOpen, onClose, isOpen } = useDisclosure()


    return (
        <Flex w={'99%'} ml={{base: 0, md: 0}}>
            <Container maxW={'8xl'}>
                <HStack>
                    <Heading paddingRight={'8'} paddingBottom={"4"} paddingTop={'4'}>Contracten</Heading>
                    <AdminFunction>
                        <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur={false} placement="bottom" isLazy>
                            <PopoverTrigger>
                            <PopoverTrigger>
                                <Button rightIcon={<PlusSquareIcon />} colorScheme="green" w="fit-content">
                                Create
                                </Button>
                            </PopoverTrigger>
                            </PopoverTrigger>
                            <PopoverContent p={5}>
                            <FocusLock returnFocus persistentFocus={false}>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <Form onCancel={onClose}/>
                            </FocusLock>
                            </PopoverContent>
                        </Popover>
                    </AdminFunction>
                </HStack>
                <Flex direction={'column'} alignItems={'flex-start'}>
                    <AsyncData loading={isLoadingSubmissions}>
                        {submissions.map((submission) => (
                            <Contract submission_id={submission.id}></Contract>
                        ))}
                    </AsyncData>
                </Flex>
            </Container>
        </Flex>
    )
}

export default ContractList;