'use client'

import {
    HStack,
    Heading,
    Flex,
    Container,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    Button,
    useDisclosure,
    FocusLock,
    Stack,
    FormControl,
    FormLabel,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberDecrementStepper,
    FormErrorMessage,
    ButtonGroup,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    TableContainer,
    Text
  } from '@chakra-ui/react'
import AsyncData from '../components/AsyncData.jsx';
import React from "react";
import { getAll } from '../backend/api/index.js';
import useSWR from 'swr';
import { PlusSquareIcon } from "@chakra-ui/icons"
import { useCallback } from 'react'
import useSWRMutation from 'swr/mutation'
import { useForm } from 'react-hook-form'
import { save } from '../backend/api/index.js'
import { useState, useEffect } from 'react'
import AdminFunction from '../components/AdminFunction.jsx';
import Toast from '../components/toast.jsx'
import Checkout from '../components/checkout/checkout.js';

const Form = ({ onCancel }) => {

    const { addToast } = Toast();
  
    const {
      trigger: saveCheckout,
      error: saveError
    } = useSWRMutation('checkouts', save);
  
    const onSubmit = useCallback(async (data) => {
      const { start_amount } = data;
      await saveCheckout({
        start_amount: start_amount
      });
      if(!saveError) {
        addToast('Checkout toegevoegd', 'Checkout is toegevoegd', 'success');
      } else {
        addToast('Checkout niet toegevoegd', 'Checkout is niet toegevoegd', 'error');
      }
    }, [ saveCheckout , addToast, saveError])
  
    const {
      handleSubmit,
      register,
      formState: {errors, isSubmitting},
      onchange,
    } = useForm()
  
    return (
        <Stack spacing={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl>
                    <FormLabel htmlFor={"start_amount"}>Start bedrag in kassa</FormLabel>
                    <NumberInput defaultValue={1.00} step={0.01} type="text"
                        {...register('start_amount', {
                            required: 'This is required'
                        })}
                        onChange={onchange}>
                    <NumberInputField type="text"
                        inputMode="text" 
                        pattern="\d*\.?\d*"  />
                    <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                    </NumberInputStepper>
                    </NumberInput>
                </FormControl>
                <FormErrorMessage>
                    {errors.name && errors.name.message}
                </FormErrorMessage>
                <ButtonGroup display='flex' justifyContent='flex-end' mt={'2'}>
                    <Button variant='outline' onClick={onCancel}>
                    Cancel
                    </Button>
                    <Button colorScheme='teal' type="submit" isLoading={isSubmitting} onClick={onCancel}>
                    Save
                    </Button>
                </ButtonGroup>
            </form>
        </Stack>
      )
    }


const Checkouts = () => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  const [opbrengst, setOpbrengst] = useState(0);

  const {
    data: checkouts = [],
    isLoading: isLoadingCheckouts,
  } = useSWR('checkouts', getAll)
  
  useEffect(() => {
    if (!isLoadingCheckouts && checkouts.length > 0) {
      const totalOpbrengst = checkouts.reduce((total, checkout) => {
        return total + parseFloat(Math.round((checkout.end_amount - checkout.start_amount) * 100) / 100);
      }, 0);
      setOpbrengst(Math.round(totalOpbrengst * 100) / 100);
    }
  }, [isLoadingCheckouts, checkouts]);

  return (
    <Flex
    w={'99%'}
    ml={{base: 0, md: 0}}>
        <Container maxW={'8xl'} mt={20}>
            <Heading paddingRight={'8'} paddingBottom={"4"} paddingTop={'4'}>Checkouts | Kassa</Heading>
            <HStack justifyContent={'space-between'} mt={'4'} mb={'4'}>
                <Popover
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                closeOnBlur={false}
                placement="bottom"
                isLazy>
                    <PopoverTrigger>
                        <Button rightIcon={<PlusSquareIcon />} colorScheme="green" w="fit-content">
                        Create
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent p={5}>
                        <FocusLock returnFocus persistentFocus={false}>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <Form onCancel={onClose} />
                        </FocusLock>
                    </PopoverContent>
                </Popover>
                <Text paddingRight={'4'} paddingBottom={"4"} paddingTop={'4'} fontSize={20}>
                    Opbrengst: € {opbrengst}
                </Text>
            </HStack>     
            <TableContainer w={'100%'}>
              <Table variant='simple' layout={'fixed'}>
                <Thead>
                  <Tr>
                    <Th>Start</Th>
                    <Th>Einde</Th>
                    <Th></Th>
                    <AdminFunction>
                        <Th></Th>
                    </AdminFunction>
                  </Tr>
                </Thead>
                <Tbody>
                  <AsyncData loading={isLoadingCheckouts}>
                    {checkouts
                    .sort((a, b) => b.date - a.date)
                    .map((checkout) => (
                        <Checkout checkout={checkout}></Checkout>
                    ))}
                  </AsyncData>
                </Tbody>
                <Tfoot>
                  <Tr>
                    <Th>Start</Th>
                    <Th>Einde</Th>
                    <Th></Th>
                    <AdminFunction>
                        <Th></Th>
                    </AdminFunction>
                  </Tr>
                </Tfoot>
              </Table>
            </TableContainer>
        </Container>
    </Flex>
  )
}

export default Checkouts