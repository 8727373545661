import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import EventsList from "./pages/Events.js";
import BarList from "./pages/Bar.js";
import ContractList from "./pages/Contracts.js";
import NoPage from "./pages/NoPage";
import { ChakraProvider } from '@chakra-ui/react';
import Sidebar from "./components/Sidebar/Sidebar.js";
import Payments from "./pages/Payments.js";
import Users from "./pages/Users.js";
import CoinsList from "./pages/Coins.js";
import Checkouts from "./pages/Checkout.js";
import SplitScreen from "./pages/login.js"
import React from "react";
import { AuthProvider } from "./contexts/Auth.context";
import PrivateRoute from "./components/PrivateRoute";

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

const router = createBrowserRouter([
  {
    path: '/login',
    element: <SplitScreen/>,
  },
  {
    path: '/',
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Sidebar SpaceVuller={Home} />
      }
    ]
  },
  {
    path: '/events',
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Sidebar SpaceVuller={EventsList}/>
      }
    ]
  },
  {
    path: '/bar',
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Sidebar SpaceVuller={BarList}/>
      }
    ]
  },
  {
    path: '/payments',
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Sidebar SpaceVuller={Payments}/>
      }
    ]
  },
  {
    path: '/users',
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Sidebar SpaceVuller={Users}/>
      }
    ]
  },
  {
    path: '/coins',
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Sidebar SpaceVuller={CoinsList}/>
      }
    ]
  },
  {
    path: '/contracts',
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Sidebar SpaceVuller={ContractList}/>
      }
    ]
  },
  {
    path: '/checkouts',
    element: <PrivateRoute />,
    children: [
      {
        index: true,
        element: <Sidebar SpaceVuller={Checkouts}/>
      }
    ]
  },
  {
    path: '*',
    element: <NoPage />,
  }
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ChakraProvider>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </ChakraProvider>
  </React.StrictMode>
);

