import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/Auth.context";
import { useEffect } from "react";
import Cookies from "js-cookie";

export default function PrivateRoute() {
    const { ready, isAuthed, setIsAuthed, checkToken, loading, user } = useAuth();
    const location = useLocation();

    useEffect(() => {
        const token = Cookies.get('jwtToken');
        setIsAuthed(checkToken(token))
    }, [location.pathname, checkToken, setIsAuthed]);

    if (loading || !ready) {
        return (
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h1>Loading...</h1>
                        <p>
                            Please wait while we are checking your credentials and loading the application.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    if (isAuthed) {
        return <Outlet context={{ user }} />;
    }

    const loginPath = `/login`;
    return <Navigate replace to={loginPath} />;
}
